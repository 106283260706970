<template>
  <UserTableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-spin :spinning="loading">
        <a-table
          :data-source="dataSource"
          :columns="studentsTableColumns"
          :pagination="{
            defaultPageSize: 10,
            total: dataSource.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} จาก ${total} คน`,
          }"
        >
          <template v-for="col in coloumnKeys" #[col]="{ text, record }" :key="col">
            <div>
              <a-input-number
                v-if="editableData[record.key] && col == 'studentNo'"
                v-model:value="editableData[record.key][col]"
                style="margin: -5px 0"
              />
              <a-select
                v-else-if="editableData[record.key] && col == 'gender'"
                v-model:value="editableData[record.key][col]"
              >
                <a-select-option value="ชาย">
                  ชาย
                </a-select-option>
                <a-select-option value="หญิง">
                  หญิง
                </a-select-option>
              </a-select>
              <a-date-picker
                v-else-if="editableData[record.key] && col == 'dateOfBirth'"
                v-model:value="editableData[record.key][col]"
                style="margin: -5px 0"
              />
              <template v-else-if="editableData[record.key] && col == 'status'">
                <a-tag :color="statusColor(text)">{{ thaiStatus(text) }}</a-tag>
              </template>
              <a-input
                v-else-if="editableData[record.key]"
                v-model:value="editableData[record.key][col]"
                style="margin: -5px 0"
              />
              <template v-else-if="col == 'dateOfBirth'">
                <div>{{ dateString(text) }}</div>
                <div v-if="!matchAgeRange(text, gradeLevel)" :style="{ color: 'orange' }">
                  {{ getAgeString(text) }} (ไม่ตรง)
                </div>
                <div v-else>{{ getAgeString(text) }}</div>
              </template>
              <template v-else-if="col == 'status'">
                <a-tag :color="statusColor(text)">{{ thaiStatus(text) }}</a-tag>
              </template>
              <template v-else>
                {{ text }}
              </template>
            </div>
          </template>
          <template #operation="{ record }">
            <div class="editable-row-operations">
              <span v-if="editableData[record.key]">
                <a-popconfirm
                  title="ยืนยันการแก้ไข?"
                  ok-text="ยืนยัน"
                  cancel-text="กลับไปแก้ไข"
                  @confirm="save(record.key)"
                >
                  <a>บันทึก</a>
                </a-popconfirm>
                <a-popconfirm
                  title="ยกเลิกการแก้ไข?"
                  ok-text="ยกเลิก"
                  cancel-text="แก้ไขต่อ"
                  @confirm="cancel(record.key)"
                >
                  <a>ยกเลิก</a>
                </a-popconfirm>
              </span>
              <a-space v-else>
                <sdButton class="btn-icon" type="default" to="#" shape="circle" @click="edit(record.key)">
                  <sdFeatherIcons type="edit" size="16" />
                </sdButton>

                <a-popconfirm
                  title="ต้องการลบแถวนี้ใช่หรือไม่?"
                  ok-text="ลบ"
                  cancel-text="ไม่ลบ"
                  @confirm="removeRow(record.key)"
                >
                  <sdButton class="btn-icon" type="default" to="#" shape="circle">
                    <sdFeatherIcons type="trash-2" size="16" />
                  </sdButton>
                </a-popconfirm>
              </a-space>
            </div>
          </template>
        </a-table>
      </a-spin>
    </TableWrapper>
  </UserTableStyleWrapper>
</template>
<script>
import { UserTableStyleWrapper } from '../style';
import { TableWrapper } from '../../../styled';
import { dateString, matchAgeRange, getAgeString } from '@/components/utilities/utilities';

import { ref, reactive, watch, computed } from 'vue';
import { useStore } from 'vuex';

import moment from 'moment';
import Validator from 'validatorjs';
import { notification } from 'ant-design-vue';

const updateNotificationError = err => {
  notification.error({
    message: err,
  });
};

const studentsTableColumns = [
  {
    title: 'เลขประจำตัวนักเรียน',
    dataIndex: 'studentCode',
    slots: { customRender: 'studentCode' },
  },
  {
    title: 'เลขที่',
    dataIndex: 'studentNo',
    slots: { customRender: 'studentNo' },
  },
  {
    title: 'ชื่อ',
    dataIndex: 'firstname',
    slots: { customRender: 'firstname' },
  },
  {
    title: 'นามสกุล',
    dataIndex: 'lastname',
    slots: { customRender: 'lastname' },
  },
  {
    title: 'ชื่อเล่น',
    dataIndex: 'nickname',
    slots: { customRender: 'nickname' },
  },
  {
    title: 'เพศ',
    dataIndex: 'gender',
    slots: { customRender: 'gender' },
  },
  {
    title: 'วันเดือนปีเกิด',
    dataIndex: 'dateOfBirth',
    slots: { customRender: 'dateOfBirth' },
  },
  {
    title: 'สถานะ',
    dataIndex: 'status',
    slots: { customRender: 'status' },
  },
  {
    title: 'แก้ไข',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
  },
];

const coloumnKeys = studentsTableColumns.map(col => col.dataIndex).filter(key => key !== 'operation');

const cloneDeep = obj => {
  var parsed = JSON.parse(JSON.stringify(obj));
  parsed.dateOfBirth = moment(parsed.dateOfBirth);
  return parsed;
};

const thaiStatus = status => {
  switch (status) {
    case 'waiting':
      return 'รอส่งข้อมูล';
    case 'duplicated-code':
      return 'เลขประจำตัวซ้ำกับในระบบ';
    case 'duplicated-name':
      return 'ชื่อ-นามสกุลซ้ำกับในระบบ';
    case 'failed':
      return 'ข้อมูลไม่ถูกต้อง';
    default:
      return 'ไม่ทราบสาเหตุ';
  }
};

const statusColor = status => {
  switch (status) {
    case 'waiting':
      return 'purple';
    case 'duplicated-code':
      return 'orange';
    case 'duplicated-name':
      return 'orange';
    case 'failed':
      return 'red';
    default:
      return 'cyan';
  }
};

const errorMessages = {
  'required.studentCode': 'กรุณาระบุ "เลขประจำตัวนักเรียน"',
  'required.studentNo': 'กรุณาระบุ "เลขที่"',
  'required.firstname': 'กรุณาระบุ "ชื่อ"',
  'required.lastname': 'กรุณาระบุ "นามสกุล"',
  'required.nickname': 'กรุณาระบุ "ชื่อเล่น"',
  'required.gender': 'กรุณาระบุ "เพศ"',
  'required.status': 'กรุณาระบุ "สถานะ"',
  'required.dateOfBirth': 'กรุณาระบุ "วัน-เดือน-ปีเกิด"',
};

const validationScheme = {
  studentCode: 'required|string',
  studentNo: 'required|integer',
  firstname: 'required|string',
  middlename: 'string',
  lastname: 'required|string',
  nickname: 'required|string',
  gender: ['required', 'regex:/(ชาย|หญิง)/'],
  status: 'required',
  dateOfBirth: 'required|date',
};

const StudentListTable = {
  name: 'StudentListTable',
  components: { UserTableStyleWrapper, TableWrapper },
  props: {
    students: {
      type: Array,
      default: [],
    },
    gradeLevel: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { students } = props;
    const { state } = useStore();
    const dataSource = ref([]);
    const editableData = reactive({});
    const loading = computed(() => state.students.loading);

    watch(
      () => props.students,
      students => {
        dataSource.value = students;
      },
    );

    dataSource.value = students;

    const edit = key => {
      const selected = dataSource.value.filter(item => key === item.key)[0];
      editableData[key] = cloneDeep(selected);
    };

    const save = key => {
      if (!editableData[key]) return;
      const validation = new Validator(editableData[key], validationScheme, errorMessages);

      if (validation.fails()) {
        const errorMessages = Object.values(validation.errors.errors)
          .flat()
          .join(', ');
        updateNotificationError(errorMessages);
        return;
      }

      dataSource.value = dataSource.value.map(item => {
        if (key === item.key) {
          editableData[key].status = 'waiting';
          return editableData[key];
        }
        return item;
      });

      delete editableData[key];

      emit('didUpdateStudents', dataSource.value);
    };

    const removeRow = key => {
      dataSource.value = dataSource.value.filter(item => item.key != key);
      delete editableData[key];
      emit('didUpdateStudents', dataSource.value);
    };

    const cancel = key => {
      delete editableData[key];
    };

    return {
      studentsTableColumns,
      dataSource,
      dateString,
      editingKey: '',
      editableData,
      thaiStatus,
      statusColor,
      cancel,
      removeRow,
      save,
      edit,
      loading,
      coloumnKeys,
      matchAgeRange,
      getAgeString,
    };
  },
};

export default StudentListTable;
</script>

<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
</style>
