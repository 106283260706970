<template>
  <sdBreadcrumb :routes="routes" />
  <sdPageHeader>
    <template #title> นำเข้านักเรียน "{{ classroom.title }}" </template>
    <template #subTitle>
      {{ getGradeLevelTitle(classroom.gradeLevel) }}
    </template>
    <template v-slot:buttons>
      <div v-if="isNoStudent" class="page-header-actions">
        <a-space>
          <sdButton key="1" type="primary" :onclick="onDownloadSample">
            <sdFeatherIcons type="users" size="16" /> <span>ดาวน์โหลดฟอร์มรายชื่อ</span>
          </sdButton>
          <sdButton key="1" type="primary" @click="$refs.fileInput.click()">
            <sdFeatherIcons type="file-plus" size="16" /> <span>อัปโหลดไฟล์รายชื่อ</span>
          </sdButton>
        </a-space>
      </div>
      <div v-else>
        <a-space>
          <sdButton
            key="2"
            type="danger"
            :disabled="loading"
            @click="
              $refs.fileInput.value = '';
              onClearFile();
            "
          >
            <sdFeatherIcons type="file-minus" size="16" /> <span>ล้างรายชื่อ</span>
          </sdButton>
          <sdButton key="3" type="success" @click="pushStudent" :disabled="loading">
            <sdFeatherIcons type="upload" size="16" /> <span>ส่งข้อมูล</span>
          </sdButton>
        </a-space>
      </div>
      <input
        ref="fileInput"
        style="display: none"
        type="file"
        ID="fileSelect"
        accept=".xlsx, .xls, .csv"
        @change="onSelectFile"
      />
    </template>
  </sdPageHeader>
  <Main>
    <a-row :gutter="[8, 8]">
      <a-col :xs="24">
        <sdAlerts
          :outlined="false"
          :closable="false"
          :showIcon="true"
          message="คำแนะนำ"
          :description="alertDescription"
          type="info"
        />
      </a-col>
      <a-col :md="24">
        <StudentListTable
          :students="newStudents"
          :gradeLevel="classroom.gradeLevel"
          @didUpdateStudents="updateNewStudents"
        />
      </a-col>
    </a-row>
  </Main>
</template>
<script>
import { Main, ImportStyleWrap } from '../../styled';
import StudentListTable from './overview/StudentTable.vue';

import { message } from 'ant-design-vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import readXlsxFile from 'read-excel-file';
import Validator from 'validatorjs';
import moment from 'moment-business-days';
import { notification } from 'ant-design-vue';
import { getGradeLevelTitle } from '@/components/utilities/utilities';

const showNotificationError = err => {
  notification.error({
    message: `แถวที่ "${err.row}" คอลัมน์ "${err.column}": ${err.error}`,
  });
};

const showValidationError = err => {
  notification.error({ message: err.message });
};

const errorMessages = {
  'required.studentCode': 'กรุณาระบุ "เลขประจำตัวนักเรียน"',
  'required.studentNo': 'กรุณาระบุ "เลขที่"',
  'required.firstname': 'กรุณาระบุ "ชื่อ"',
  'required.lastname': 'กรุณาระบุ "นามสกุล"',
  'required.nickname': 'กรุณาระบุ "ชื่อเล่น"',
  'required.gender': 'กรุณาระบุ "เพศ"',
  'required.status': 'กรุณาระบุ "สถานะ"',
  'required.dateOfBirth': 'กรุณาระบุ "วัน-เดือน-ปีเกิด"',
};

const validationScheme = {
  studentCode: 'required|string',
  studentNo: 'required|integer',
  firstname: 'required|string',
  middlename: 'string',
  lastname: 'required|string',
  nickname: 'required|string',
  gender: ['required', 'regex:/(ชาย|หญิง)/'],
  status: 'required',
  dateOfBirth: 'required|date',
};

const toTableDataSource = (student, index, status) => {
  const { studentCode, studentNo, firstname, lastname, gender, nickname, dateOfBirth } = student;
  return {
    key: index,
    studentCode,
    studentNo,
    firstname,
    middlename: '',
    lastname,
    nickname,
    gender: gender == 'MALE' ? 'ชาย' : 'หญิง',
    dateOfBirth,
    status,
  };
};

const toPushValue = (student, joinedAt) => {
  const { studentCode, studentNo, firstname, lastname, gender, nickname, dateOfBirth } = student;
  return {
    studentCode,
    studentNo,
    firstname,
    middlename: '',
    lastname,
    nickname,
    gender: gender == 'ชาย' ? 'MALE' : 'FEMALE',
    status: 'ACTIVE',
    dateOfBirth: moment(dateOfBirth).toDate(),
    joinedAt: joinedAt,
  };
};

const mapStudentValues = (students, term) => {
  const validations = students.map((student, index) => {
    const validation = new Validator(student, validationScheme, errorMessages);
    let message = '';

    if (validation.fails()) {
      message = Object.values(validation.errors.errors).join(', ');
    }

    return {
      failed: validation.fails(),
      row: index + 1,
      message,
    };
  });

  const errors = validations.filter(item => item.failed);

  if (errors.length > 0) {
    const messages = errors
      .map(error => {
        return `แถวที่ ${error.row}: ${error.message}`;
      })
      .join('\n');
    throw new Error(messages);
  }

  return students.map(student => toPushValue(student, term.startDate));
};

const demoFileUrl =
  'https://firebasestorage.googleapis.com/v0/b/the-hugo-91be1.appspot.com/o/demo%2F%E0%B8%95%E0%B8%B1%E0%B8%A7%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B8%8A%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%99%E0%B8%B1%E0%B8%81%E0%B9%80%E0%B8%A3%E0%B8%B5%E0%B8%A2%E0%B8%99.xlsx?alt=media&token=8ba139fc-5cd6-4769-8dcb-36b5ce3d0359';

const Import = {
  name: 'Import',
  components: { Main, ImportStyleWrap, StudentListTable },
  props: {
    classroomId: Number,
  },
  setup(props) {
    const { state, dispatch, getters } = useStore();
    const file = ref(null);
    const list = ref(null);
    const submitValues = ref({});
    const xlsFile = ref(null);
    const newStudents = ref([]);
    const fileInput = ref(null);
    const fileError = ref(null);

    const alertDescription = ref(
      `หากท่านไม่มีตัวอย่างไฟล์ Excel ให้ดาวน์โหลดได้จากปุ่ม "ดาวน์โหลดฟอร์มรายชื่อ" ด้านบนหลังจากนั้นให้นำข้อมูลนักเรียนของท่านมากรอกในไฟล์ตามตารางให้ถูกต้อง หลังจากนั้นให้นำมา "อัปโหลดไฟล์รายชื่อ"`,
    );
    const schoolYear = computed(() => state.schoolYear.selected);
    const classroom = computed(() => getters.getClassroomById(props.classroomId));
    const loading = computed(() => state.students.loading);

    const currentTerm = computed(() => {
      const terms = schoolYear.value.terms.sort((a, b) => {
        return a.termNo < b.termNo;
      });

      const found = terms.find(term => {
        const startDate = moment(term.startDate);
        const endDate = moment(term.endDate);
        const today = moment();
        return today.isBetween(startDate, endDate, undefined, '[]');
      });

      if (!found) {
        const [term1, term2] = terms;
        const term2Start = moment(term2.startDate);
        const today = moment();
        if (today.isBefore(term2Start)) {
          return term1;
        }
        return term2;
      }

      return found;
    });

    const isNoStudent = computed(() => {
      if (!newStudents.value) return true;
      return newStudents.value.length == 0;
    });

    const routes = computed(() => {
      return [
        {
          path: '',
          breadcrumbName: 'หน้าแรก',
        },
        {
          path: `/classroom`,
          breadcrumbName: 'ห้องเรียนทั้งหมด',
        },
        {
          path: `/schoolYears/${schoolYear.value.id}/classrooms/${props.classroomId}`,
          breadcrumbName: classroom.value.title,
        },
        {
          path: `/classroom/${props.classroomId}/import-student`,
          breadcrumbName: 'นำเข้านักเรียนด้วยไฟล์',
        },
      ];
    });

    const schema = {
      เลขประจำตัวนักเรียน: {
        prop: 'studentCode',
        type: String,
      },
      เลขที่: { prop: 'studentNo', type: Number },
      ชื่อ: { prop: 'firstname', type: String },
      นามสกุล: { prop: 'lastname', type: String },
      ชื่อเล่น: { prop: 'nickname', type: String },
      เพศ: { prop: 'gender', type: String },
      'วัน-เดือน-ปีเกิด': {
        prop: 'dateOfBirth',
        type: value => {
          if (!value) {
            throw new Error('ไม่มีข้อมูลวันเกิด');
          }
          let dateString = value.replace(/\s/g, '').replace(/\//g, '-');
          const yearPattern = /(\d{1,2})-(\d{1,2})-(\d{4})/;
          const matched = dateString.match(yearPattern);

          if (!matched || !matched[3]) {
            throw new Error(`ข้อมูลวันเกิดไม่ถูกต้อง ต้องเป็น 'วว-ดด-ปปปป' เท่านั้น`);
          } else {
            const yearString = matched[3];
            let year = parseInt(yearString);
            if (year > 2500) {
              year = year - 543;
            }
            const dateOfBirthString = value.replace(yearString, `${year}`);
            return moment(dateOfBirthString, 'DD-MM-YYYY').toDate();
          }
        },
      },
    };

    const onSelectFile = event => {
      const selectedFile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(selectedFile, { schema }).then(({ rows, errors }) => {
        if (errors.length > 0) {
          errors.forEach(error => {
            console.log(error);
            showNotificationError(error);
          });
        }

        newStudents.value = rows.map((row, index) => toTableDataSource(row, index, 'waiting'));
      });
    };

    const onClearFile = () => {
      newStudents.value = [];
    };

    const onDownloadSample = () => {
      window.open(demoFileUrl);
    };

    const updateNewStudents = students => {
      newStudents.value = students;
    };

    const clearStudentList = () => {
      fileInput.value = null;
      newStudents.value = null;
    };

    const updateStudentStatus = (codeDuplicated = [], nameDuplicated = [], failed = []) => {
      const newCodeDuplicated = codeDuplicated.map((student, index) => {
        return toTableDataSource(student, index, 'duplicated-code');
      });

      const newNameDuplicated = nameDuplicated.map((student, index) => {
        return toTableDataSource(student, index, 'duplicated-name');
      });

      const newFailed = failed.map((student, index) => {
        return toTableDataSource(student, index, 'failed');
      });

      const newStudentList = newCodeDuplicated.concat(newNameDuplicated).concat(newFailed);

      newStudents.value = newStudentList.map((student, index) => {
        student.key = index;
        return student;
      });
    };

    const pushStudent = async () => {
      const schoolYearId = schoolYear.value.id;
      const classroomId = props.classroomId;
      try {
        const students = mapStudentValues(newStudents.value, currentTerm.value);

        const { codeDuplicated, nameDuplicated, failed, success } = await dispatch('bulkCreateStudents', {
          schoolYearId,
          classroomId,
          students,
        });

        updateStudentStatus(codeDuplicated, nameDuplicated, failed, success);
      } catch (error) {
        showValidationError(error);
      }
    };

    const fileUploadProps = {
      name: 'file',
      multiple: true,
      action: null,
      onChange(info) {
        const { status } = info.file;
        if (status !== 'uploading') {
          file.value = info.file;
          list.value = info.fileList;
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      listType: 'picture',
      defaultFileList: [],
      showUploadList: {
        showRemoveIcon: true,
        removeIcon: <sdFeatherIcons type="trash-2" onClick={e => console.log(e, 'custom removeIcon event')} />,
      },
    };

    return {
      routes,
      onDownloadSample,
      submitValues,
      fileUploadProps,
      xlsFile,
      onSelectFile,
      onClearFile,
      newStudents,
      pushStudent,
      classroom,
      updateNewStudents,
      clearStudentList,
      updateStudentStatus,
      fileError,
      isNoStudent,
      alertDescription,
      getGradeLevelTitle,
      loading,
    };
  },
};

export default Import;
</script>
